import { type GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

const Error404Page: React.FC = () => {
  const { t } = useTranslation(['common', 'error'])

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="p-5 flex flex-col items-center text-center gap-8">
        <div className="w-90 md:w-full">
          <h2 className="text-2xl mb-2">{t('error:page_not_found')} ⚠️</h2>
          <p className="text-sm">{t('error:page_not_found_paragraph')}</p>
        </div>
        <Link href='/'>
          <button className="bg-primary text-white px-14 py-2 rounded-full hover:bg-secondary active:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50">
            {t('error:back_to_home')}
          </button>
        </Link>
      </div>
    </div>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['common', 'error']))
    }
  }
}

export default Error404Page
